var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _vm._m(0),
    _c("div", { staticClass: "col-md-12 card" }, [
      _vm._m(1),
      _c("div", { staticClass: "card-body row" }, [
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c(
              "el-select",
              {
                staticClass: "select-default",
                attrs: { placeholder: "Per page" },
                model: {
                  value: _vm.pagination.perPage,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "perPage", $$v)
                  },
                  expression: "pagination.perPage",
                },
              },
              _vm._l(_vm.pagination.perPageOptions, function (item) {
                return _c("el-option", {
                  key: item,
                  staticClass: "select-default",
                  attrs: { label: item, value: item },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "div",
            { staticClass: "pull-right" },
            [
              _c("fg-input", {
                staticClass: "input-sm",
                attrs: {
                  placeholder: "Search",
                  "addon-right-icon": "nc-icon nc-zoom-split",
                },
                model: {
                  value: _vm.searchQuery,
                  callback: function ($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "col-sm-12" },
          [
            _c(
              "el-table",
              {
                staticClass: "table-striped",
                staticStyle: { width: "100%" },
                attrs: { data: _vm.queriedData, border: "" },
              },
              [
                _vm._l(_vm.tableColumns, function (column) {
                  return _c("el-table-column", {
                    key: column.label,
                    attrs: {
                      "min-width": column.minWidth,
                      prop: column.prop,
                      label: column.label,
                    },
                  })
                }),
                _c("el-table-column", {
                  attrs: {
                    "min-width": 120,
                    fixed: "right",
                    "class-name": "td-actions",
                    label: "Actions",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "p-button",
                            {
                              attrs: { type: "info", size: "sm", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleLike(props.$index, props.row)
                                },
                              },
                            },
                            [_c("i", { staticClass: "fa fa-user" })]
                          ),
                          _c(
                            "p-button",
                            {
                              attrs: { type: "success", size: "sm", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(props.$index, props.row)
                                },
                              },
                            },
                            [_c("i", { staticClass: "fa fa-edit" })]
                          ),
                          _c(
                            "p-button",
                            {
                              attrs: { type: "danger", size: "sm", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(
                                    props.$index,
                                    props.row
                                  )
                                },
                              },
                            },
                            [_c("i", { staticClass: "fa fa-times" })]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c("div", { staticClass: "col-sm-6 pagination-info" }, [
          _c("p", { staticClass: "category" }, [
            _vm._v(
              "Showing " +
                _vm._s(_vm.from + 1) +
                " to " +
                _vm._s(_vm.to) +
                " of " +
                _vm._s(_vm.total) +
                " entries"
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("p-pagination", {
              staticClass: "pull-right",
              attrs: {
                "per-page": _vm.pagination.perPage,
                total: _vm.pagination.total,
              },
              model: {
                value: _vm.pagination.currentPage,
                callback: function ($$v) {
                  _vm.$set(_vm.pagination, "currentPage", $$v)
                },
                expression: "pagination.currentPage",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12" }, [
      _c("h4", { staticClass: "title" }, [
        _vm._v("Custom table with pagination"),
      ]),
      _c("p", { staticClass: "category" }, [
        _vm._v("\n      We combine "),
        _c(
          "a",
          {
            attrs: {
              href: "http://element.eleme.io/#/en-US/component/quickstart",
              target: "_blank",
            },
          },
          [_vm._v("Element-UI")]
        ),
        _vm._v(
          "\n      table functionalities together with a custom pagination component\n      which should provide a very good starting point to integrate tables in your application.\n      Check out more functionalities at "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "http://element.eleme.io/#/en-US/component/table",
              target: "_blank",
            },
          },
          [_vm._v("Element-UI table documentation")]
        ),
        _vm._v(".\n     "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "category" }, [_vm._v("Extended tables")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }